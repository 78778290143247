import React from "react"

import MarketingLayout from '../layouts/marketing.js'
import Styles from '../styles/home.module.scss'
import Icon from '../components/icon.js'

export default function Home() {
  return (
    <MarketingLayout title="Carrick Integrations"
                     description="High-quality and custom designed integrations built directly into Help Scout, empowering your Support team to be efficient and empathetic."
                     shortDescription="Integrations to super charge your Help Scout app">
      <div className="wrapper">
        <h1>
          <img src="/img/logo.svg" alt="Carrick Integrations" />
          Carrick
        </h1>
        <h2>Integrations to <em>super-charge</em> your Help Scout app</h2>
      </div>
      <div className={Styles.appList}>
        <div className={Styles.app}>
          <h2>Issues</h2>
          <span className={Styles.integrationType}>Mailbox Integration</span>
          <p>Track GitHub Issues directly from Help Scout. Carrick Issues integrates your Help Scout mailbox directly with GitHub so your team can coordinate faster and serve customers better.</p>
          <ul className={Styles.featureList}>
            <li>Live Sidebar App</li>
            <li>One-click Issue Creation</li>
            <li>Many-to-many Issue Linking</li>
            <li>Track all linked conversations</li>
          </ul>
          <a className={Styles.appCta} href="/issues">Learn More</a>
          <a title="Install Now" className={Styles.install} href="https://carrick-processor.herokuapp.com/signup/issues">
            <Icon name="plus-circle-fill" />
          </a>
        </div>

        <div className={Styles.app}>
          <h2>Status</h2>
          <span className={Styles.integrationType}>Mailbox Integration</span>
          <p>Get Status Page information directly within Help Scout. Carrick Status shows you the latest info from your status page so that you can build Trust with your customers.</p>
          <ul className={Styles.featureList}>
            <li>Atlassian StatusPage Integration</li>
            <li>Live Sidebar App</li>
            <li>Real-time Updates</li>
            <li>Component-specific Status</li>
          </ul>
          <a className={Styles.appCta} href="/status">Learn More</a>
          <a title="Install Now" className={Styles.install} href="https://carrick-processor.herokuapp.com/signup/status">
            <Icon name="plus-circle-fill" />
          </a>
        </div>
      </div>
    </MarketingLayout>
  )
}
